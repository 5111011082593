var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
(function () {
  var $,
      AbstractChosen,
      Chosen,
      SelectParser,
      bind = function (fn, me) {
    return function () {
      return fn.apply(me, arguments);
    };
  },
      extend = function (child, parent) {
    for (var key in parent) {
      if (hasProp.call(parent, key)) child[key] = parent[key];
    }

    function ctor() {
      (this || _global).constructor = child;
    }

    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
    child.__super__ = parent.prototype;
    return child;
  },
      hasProp = {}.hasOwnProperty;

  SelectParser = function () {
    function SelectParser() {
      (this || _global).options_index = 0;
      (this || _global).parsed = [];
    }

    SelectParser.prototype.add_node = function (child) {
      if (child.nodeName.toUpperCase() === "OPTGROUP") {
        return this.add_group(child);
      } else {
        return this.add_option(child);
      }
    };

    SelectParser.prototype.add_group = function (group) {
      var group_position, i, len, option, ref, results1;
      group_position = (this || _global).parsed.length;

      (this || _global).parsed.push({
        array_index: group_position,
        group: true,
        label: group.label,
        title: group.title ? group.title : void 0,
        children: 0,
        disabled: group.disabled,
        classes: group.className
      });

      ref = group.childNodes;
      results1 = [];

      for (i = 0, len = ref.length; i < len; i++) {
        option = ref[i];
        results1.push(this.add_option(option, group_position, group.disabled));
      }

      return results1;
    };

    SelectParser.prototype.add_option = function (option, group_position, group_disabled) {
      if (option.nodeName.toUpperCase() === "OPTION") {
        if (option.text !== "") {
          if (group_position != null) {
            (this || _global).parsed[group_position].children += 1;
          }

          (this || _global).parsed.push({
            array_index: (this || _global).parsed.length,
            options_index: (this || _global).options_index,
            value: option.value,
            text: option.text,
            html: option.innerHTML,
            title: option.title ? option.title : void 0,
            selected: option.selected,
            disabled: group_disabled === true ? group_disabled : option.disabled,
            group_array_index: group_position,
            group_label: group_position != null ? (this || _global).parsed[group_position].label : null,
            classes: option.className,
            style: option.style.cssText
          });
        } else {
          (this || _global).parsed.push({
            array_index: (this || _global).parsed.length,
            options_index: (this || _global).options_index,
            empty: true
          });
        }

        return (this || _global).options_index += 1;
      }
    };

    return SelectParser;
  }();

  SelectParser.select_to_array = function (select) {
    var child, i, len, parser, ref;
    parser = new SelectParser();
    ref = select.childNodes;

    for (i = 0, len = ref.length; i < len; i++) {
      child = ref[i];
      parser.add_node(child);
    }

    return parser.parsed;
  };

  AbstractChosen = function () {
    function AbstractChosen(form_field, options1) {
      (this || _global).form_field = form_field;
      (this || _global).options = options1 != null ? options1 : {};
      (this || _global).label_click_handler = bind((this || _global).label_click_handler, this || _global);

      if (!AbstractChosen.browser_is_supported()) {
        return;
      }

      (this || _global).is_multiple = (this || _global).form_field.multiple;
      this.set_default_text();
      this.set_default_values();
      this.setup();
      this.set_up_html();
      this.register_observers();
      this.on_ready();
    }

    AbstractChosen.prototype.set_default_values = function () {
      (this || _global).click_test_action = function (_this) {
        return function (evt) {
          return _this.test_active_click(evt);
        };
      }(this || _global);

      (this || _global).activate_action = function (_this) {
        return function (evt) {
          return _this.activate_field(evt);
        };
      }(this || _global);

      (this || _global).active_field = false;
      (this || _global).mouse_on_container = false;
      (this || _global).results_showing = false;
      (this || _global).result_highlighted = null;
      (this || _global).is_rtl = (this || _global).options.rtl || /\bchosen-rtl\b/.test((this || _global).form_field.className);
      (this || _global).allow_single_deselect = (this || _global).options.allow_single_deselect != null && (this || _global).form_field.options[0] != null && (this || _global).form_field.options[0].text === "" ? (this || _global).options.allow_single_deselect : false;
      (this || _global).disable_search_threshold = (this || _global).options.disable_search_threshold || 0;
      (this || _global).disable_search = (this || _global).options.disable_search || false;
      (this || _global).enable_split_word_search = (this || _global).options.enable_split_word_search != null ? (this || _global).options.enable_split_word_search : true;
      (this || _global).group_search = (this || _global).options.group_search != null ? (this || _global).options.group_search : true;
      (this || _global).search_contains = (this || _global).options.search_contains || false;
      (this || _global).single_backstroke_delete = (this || _global).options.single_backstroke_delete != null ? (this || _global).options.single_backstroke_delete : true;
      (this || _global).max_selected_options = (this || _global).options.max_selected_options || Infinity;
      (this || _global).inherit_select_classes = (this || _global).options.inherit_select_classes || false;
      (this || _global).display_selected_options = (this || _global).options.display_selected_options != null ? (this || _global).options.display_selected_options : true;
      (this || _global).display_disabled_options = (this || _global).options.display_disabled_options != null ? (this || _global).options.display_disabled_options : true;
      (this || _global).include_group_label_in_selected = (this || _global).options.include_group_label_in_selected || false;
      (this || _global).max_shown_results = (this || _global).options.max_shown_results || Number.POSITIVE_INFINITY;
      (this || _global).case_sensitive_search = (this || _global).options.case_sensitive_search || false;
      return (this || _global).hide_results_on_select = (this || _global).options.hide_results_on_select != null ? (this || _global).options.hide_results_on_select : true;
    };

    AbstractChosen.prototype.set_default_text = function () {
      if ((this || _global).form_field.getAttribute("data-placeholder")) {
        (this || _global).default_text = (this || _global).form_field.getAttribute("data-placeholder");
      } else if ((this || _global).is_multiple) {
        (this || _global).default_text = (this || _global).options.placeholder_text_multiple || (this || _global).options.placeholder_text || AbstractChosen.default_multiple_text;
      } else {
        (this || _global).default_text = (this || _global).options.placeholder_text_single || (this || _global).options.placeholder_text || AbstractChosen.default_single_text;
      }

      (this || _global).default_text = this.escape_html((this || _global).default_text);
      return (this || _global).results_none_found = (this || _global).form_field.getAttribute("data-no_results_text") || (this || _global).options.no_results_text || AbstractChosen.default_no_result_text;
    };

    AbstractChosen.prototype.choice_label = function (item) {
      if ((this || _global).include_group_label_in_selected && item.group_label != null) {
        return "<b class='group-name'>" + this.escape_html(item.group_label) + "</b>" + item.html;
      } else {
        return item.html;
      }
    };

    AbstractChosen.prototype.mouse_enter = function () {
      return (this || _global).mouse_on_container = true;
    };

    AbstractChosen.prototype.mouse_leave = function () {
      return (this || _global).mouse_on_container = false;
    };

    AbstractChosen.prototype.input_focus = function (evt) {
      if ((this || _global).is_multiple) {
        if (!(this || _global).active_field) {
          return setTimeout(function (_this) {
            return function () {
              return _this.container_mousedown();
            };
          }(this || _global), 50);
        }
      } else {
        if (!(this || _global).active_field) {
          return this.activate_field();
        }
      }
    };

    AbstractChosen.prototype.input_blur = function (evt) {
      if (!(this || _global).mouse_on_container) {
        (this || _global).active_field = false;
        return setTimeout(function (_this) {
          return function () {
            return _this.blur_test();
          };
        }(this || _global), 100);
      }
    };

    AbstractChosen.prototype.label_click_handler = function (evt) {
      if ((this || _global).is_multiple) {
        return this.container_mousedown(evt);
      } else {
        return this.activate_field();
      }
    };

    AbstractChosen.prototype.results_option_build = function (options) {
      var content, data, data_content, i, len, ref, shown_results;
      content = "";
      shown_results = 0;
      ref = (this || _global).results_data;

      for (i = 0, len = ref.length; i < len; i++) {
        data = ref[i];
        data_content = "";

        if (data.group) {
          data_content = this.result_add_group(data);
        } else {
          data_content = this.result_add_option(data);
        }

        if (data_content !== "") {
          shown_results++;
          content += data_content;
        }

        if (options != null ? options.first : void 0) {
          if (data.selected && (this || _global).is_multiple) {
            this.choice_build(data);
          } else if (data.selected && !(this || _global).is_multiple) {
            this.single_set_selected_text(this.choice_label(data));
          }
        }

        if (shown_results >= (this || _global).max_shown_results) {
          break;
        }
      }

      return content;
    };

    AbstractChosen.prototype.result_add_option = function (option) {
      var classes, option_el;

      if (!option.search_match) {
        return "";
      }

      if (!this.include_option_in_results(option)) {
        return "";
      }

      classes = [];

      if (!option.disabled && !(option.selected && (this || _global).is_multiple)) {
        classes.push("active-result");
      }

      if (option.disabled && !(option.selected && (this || _global).is_multiple)) {
        classes.push("disabled-result");
      }

      if (option.selected) {
        classes.push("result-selected");
      }

      if (option.group_array_index != null) {
        classes.push("group-option");
      }

      if (option.classes !== "") {
        classes.push(option.classes);
      }

      option_el = document.createElement("li");
      option_el.className = classes.join(" ");

      if (option.style) {
        option_el.style.cssText = option.style;
      }

      option_el.setAttribute("data-option-array-index", option.array_index);
      option_el.innerHTML = option.highlighted_html || option.html;

      if (option.title) {
        option_el.title = option.title;
      }

      return this.outerHTML(option_el);
    };

    AbstractChosen.prototype.result_add_group = function (group) {
      var classes, group_el;

      if (!(group.search_match || group.group_match)) {
        return "";
      }

      if (!(group.active_options > 0)) {
        return "";
      }

      classes = [];
      classes.push("group-result");

      if (group.classes) {
        classes.push(group.classes);
      }

      group_el = document.createElement("li");
      group_el.className = classes.join(" ");
      group_el.innerHTML = group.highlighted_html || this.escape_html(group.label);

      if (group.title) {
        group_el.title = group.title;
      }

      return this.outerHTML(group_el);
    };

    AbstractChosen.prototype.results_update_field = function () {
      this.set_default_text();

      if (!(this || _global).is_multiple) {
        this.results_reset_cleanup();
      }

      this.result_clear_highlight();
      this.results_build();

      if ((this || _global).results_showing) {
        return this.winnow_results();
      }
    };

    AbstractChosen.prototype.reset_single_select_options = function () {
      var i, len, ref, result, results1;
      ref = (this || _global).results_data;
      results1 = [];

      for (i = 0, len = ref.length; i < len; i++) {
        result = ref[i];

        if (result.selected) {
          results1.push(result.selected = false);
        } else {
          results1.push(void 0);
        }
      }

      return results1;
    };

    AbstractChosen.prototype.results_toggle = function () {
      if ((this || _global).results_showing) {
        return this.results_hide();
      } else {
        return this.results_show();
      }
    };

    AbstractChosen.prototype.results_search = function (evt) {
      if ((this || _global).results_showing) {
        return this.winnow_results();
      } else {
        return this.results_show();
      }
    };

    AbstractChosen.prototype.winnow_results = function (options) {
      var escapedQuery, fix, i, len, option, prefix, query, ref, regex, results, results_group, search_match, startpos, suffix, text;
      this.no_results_clear();
      results = 0;
      query = this.get_search_text();
      escapedQuery = query.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
      regex = this.get_search_regex(escapedQuery);
      ref = (this || _global).results_data;

      for (i = 0, len = ref.length; i < len; i++) {
        option = ref[i];
        option.search_match = false;
        results_group = null;
        search_match = null;
        option.highlighted_html = "";

        if (this.include_option_in_results(option)) {
          if (option.group) {
            option.group_match = false;
            option.active_options = 0;
          }

          if (option.group_array_index != null && (this || _global).results_data[option.group_array_index]) {
            results_group = (this || _global).results_data[option.group_array_index];

            if (results_group.active_options === 0 && results_group.search_match) {
              results += 1;
            }

            results_group.active_options += 1;
          }

          text = option.group ? option.label : option.text;

          if (!(option.group && !(this || _global).group_search)) {
            search_match = this.search_string_match(text, regex);
            option.search_match = search_match != null;

            if (option.search_match && !option.group) {
              results += 1;
            }

            if (option.search_match) {
              if (query.length) {
                startpos = search_match.index;
                prefix = text.slice(0, startpos);
                fix = text.slice(startpos, startpos + query.length);
                suffix = text.slice(startpos + query.length);
                option.highlighted_html = this.escape_html(prefix) + "<em>" + this.escape_html(fix) + "</em>" + this.escape_html(suffix);
              }

              if (results_group != null) {
                results_group.group_match = true;
              }
            } else if (option.group_array_index != null && (this || _global).results_data[option.group_array_index].search_match) {
              option.search_match = true;
            }
          }
        }
      }

      this.result_clear_highlight();

      if (results < 1 && query.length) {
        this.update_results_content("");
        return this.no_results(query);
      } else {
        this.update_results_content(this.results_option_build());

        if (!(options != null ? options.skip_highlight : void 0)) {
          return this.winnow_results_set_highlight();
        }
      }
    };

    AbstractChosen.prototype.get_search_regex = function (escaped_search_string) {
      var regex_flag, regex_string;
      regex_string = (this || _global).search_contains ? escaped_search_string : "(^|\\s|\\b)" + escaped_search_string + "[^\\s]*";

      if (!((this || _global).enable_split_word_search || (this || _global).search_contains)) {
        regex_string = "^" + regex_string;
      }

      regex_flag = (this || _global).case_sensitive_search ? "" : "i";
      return new RegExp(regex_string, regex_flag);
    };

    AbstractChosen.prototype.search_string_match = function (search_string, regex) {
      var match;
      match = regex.exec(search_string);

      if (!(this || _global).search_contains && (match != null ? match[1] : void 0)) {
        match.index += 1;
      }

      return match;
    };

    AbstractChosen.prototype.choices_count = function () {
      var i, len, option, ref;

      if ((this || _global).selected_option_count != null) {
        return (this || _global).selected_option_count;
      }

      (this || _global).selected_option_count = 0;
      ref = (this || _global).form_field.options;

      for (i = 0, len = ref.length; i < len; i++) {
        option = ref[i];

        if (option.selected) {
          (this || _global).selected_option_count += 1;
        }
      }

      return (this || _global).selected_option_count;
    };

    AbstractChosen.prototype.choices_click = function (evt) {
      evt.preventDefault();
      this.activate_field();

      if (!((this || _global).results_showing || (this || _global).is_disabled)) {
        return this.results_show();
      }
    };

    AbstractChosen.prototype.keydown_checker = function (evt) {
      var ref, stroke;
      stroke = (ref = evt.which) != null ? ref : evt.keyCode;
      this.search_field_scale();

      if (stroke !== 8 && (this || _global).pending_backstroke) {
        this.clear_backstroke();
      }

      switch (stroke) {
        case 8:
          (this || _global).backstroke_length = this.get_search_field_value().length;
          break;

        case 9:
          if ((this || _global).results_showing && !(this || _global).is_multiple) {
            this.result_select(evt);
          }

          (this || _global).mouse_on_container = false;
          break;

        case 13:
          if ((this || _global).results_showing) {
            evt.preventDefault();
          }

          break;

        case 27:
          if ((this || _global).results_showing) {
            evt.preventDefault();
          }

          break;

        case 32:
          if ((this || _global).disable_search) {
            evt.preventDefault();
          }

          break;

        case 38:
          evt.preventDefault();
          this.keyup_arrow();
          break;

        case 40:
          evt.preventDefault();
          this.keydown_arrow();
          break;
      }
    };

    AbstractChosen.prototype.keyup_checker = function (evt) {
      var ref, stroke;
      stroke = (ref = evt.which) != null ? ref : evt.keyCode;
      this.search_field_scale();

      switch (stroke) {
        case 8:
          if ((this || _global).is_multiple && (this || _global).backstroke_length < 1 && this.choices_count() > 0) {
            this.keydown_backstroke();
          } else if (!(this || _global).pending_backstroke) {
            this.result_clear_highlight();
            this.results_search();
          }

          break;

        case 13:
          evt.preventDefault();

          if ((this || _global).results_showing) {
            this.result_select(evt);
          }

          break;

        case 27:
          if ((this || _global).results_showing) {
            this.results_hide();
          }

          break;

        case 9:
        case 16:
        case 17:
        case 18:
        case 38:
        case 40:
        case 91:
          break;

        default:
          this.results_search();
          break;
      }
    };

    AbstractChosen.prototype.clipboard_event_checker = function (evt) {
      if ((this || _global).is_disabled) {
        return;
      }

      return setTimeout(function (_this) {
        return function () {
          return _this.results_search();
        };
      }(this || _global), 50);
    };

    AbstractChosen.prototype.container_width = function () {
      if ((this || _global).options.width != null) {
        return (this || _global).options.width;
      } else {
        return (this || _global).form_field.offsetWidth + "px";
      }
    };

    AbstractChosen.prototype.include_option_in_results = function (option) {
      if ((this || _global).is_multiple && !(this || _global).display_selected_options && option.selected) {
        return false;
      }

      if (!(this || _global).display_disabled_options && option.disabled) {
        return false;
      }

      if (option.empty) {
        return false;
      }

      return true;
    };

    AbstractChosen.prototype.search_results_touchstart = function (evt) {
      (this || _global).touch_started = true;
      return this.search_results_mouseover(evt);
    };

    AbstractChosen.prototype.search_results_touchmove = function (evt) {
      (this || _global).touch_started = false;
      return this.search_results_mouseout(evt);
    };

    AbstractChosen.prototype.search_results_touchend = function (evt) {
      if ((this || _global).touch_started) {
        return this.search_results_mouseup(evt);
      }
    };

    AbstractChosen.prototype.outerHTML = function (element) {
      var tmp;

      if (element.outerHTML) {
        return element.outerHTML;
      }

      tmp = document.createElement("div");
      tmp.appendChild(element);
      return tmp.innerHTML;
    };

    AbstractChosen.prototype.get_single_html = function () {
      return "<a class=\"chosen-single chosen-default\">\n  <span>" + (this || _global).default_text + "</span>\n  <div><b></b></div>\n</a>\n<div class=\"chosen-drop\">\n  <div class=\"chosen-search\">\n    <input class=\"chosen-search-input\" type=\"text\" autocomplete=\"off\" />\n  </div>\n  <ul class=\"chosen-results\"></ul>\n</div>";
    };

    AbstractChosen.prototype.get_multi_html = function () {
      return "<ul class=\"chosen-choices\">\n  <li class=\"search-field\">\n    <input class=\"chosen-search-input\" type=\"text\" autocomplete=\"off\" value=\"" + (this || _global).default_text + "\" />\n  </li>\n</ul>\n<div class=\"chosen-drop\">\n  <ul class=\"chosen-results\"></ul>\n</div>";
    };

    AbstractChosen.prototype.get_no_results_html = function (terms) {
      return "<li class=\"no-results\">\n  " + (this || _global).results_none_found + " <span>" + this.escape_html(terms) + "</span>\n</li>";
    };

    AbstractChosen.browser_is_supported = function () {
      if ("Microsoft Internet Explorer" === window.navigator.appName) {
        return document.documentMode >= 8;
      }

      if (/iP(od|hone)/i.test(window.navigator.userAgent) || /IEMobile/i.test(window.navigator.userAgent) || /Windows Phone/i.test(window.navigator.userAgent) || /BlackBerry/i.test(window.navigator.userAgent) || /BB10/i.test(window.navigator.userAgent) || /Android.*Mobile/i.test(window.navigator.userAgent)) {
        return false;
      }

      return true;
    };

    AbstractChosen.default_multiple_text = "Select Some Options";
    AbstractChosen.default_single_text = "Select an Option";
    AbstractChosen.default_no_result_text = "No results match";
    return AbstractChosen;
  }();

  $ = jQuery;
  $.fn.extend({
    chosen: function (options) {
      if (!AbstractChosen.browser_is_supported()) {
        return this || _global;
      }

      return this.each(function (input_field) {
        var $this, chosen;
        $this = $(this || _global);
        chosen = $this.data("chosen");

        if (options === "destroy") {
          if (chosen instanceof Chosen) {
            chosen.destroy();
          }

          return;
        }

        if (!(chosen instanceof Chosen)) {
          $this.data("chosen", new Chosen(this || _global, options));
        }
      });
    }
  });

  Chosen = function (superClass) {
    extend(Chosen, superClass);

    function Chosen() {
      return Chosen.__super__.constructor.apply(this || _global, arguments);
    }

    Chosen.prototype.setup = function () {
      (this || _global).form_field_jq = $((this || _global).form_field);
      return (this || _global).current_selectedIndex = (this || _global).form_field.selectedIndex;
    };

    Chosen.prototype.set_up_html = function () {
      var container_classes, container_props;
      container_classes = ["chosen-container"];
      container_classes.push("chosen-container-" + ((this || _global).is_multiple ? "multi" : "single"));

      if ((this || _global).inherit_select_classes && (this || _global).form_field.className) {
        container_classes.push((this || _global).form_field.className);
      }

      if ((this || _global).is_rtl) {
        container_classes.push("chosen-rtl");
      }

      container_props = {
        "class": container_classes.join(" "),
        "title": (this || _global).form_field.title
      };

      if ((this || _global).form_field.id.length) {
        container_props.id = (this || _global).form_field.id.replace(/[^\w]/g, "_") + "_chosen";
      }

      (this || _global).container = $("<div />", container_props);

      (this || _global).container.width(this.container_width());

      if ((this || _global).is_multiple) {
        (this || _global).container.html(this.get_multi_html());
      } else {
        (this || _global).container.html(this.get_single_html());
      }

      (this || _global).form_field_jq.hide().after((this || _global).container);

      (this || _global).dropdown = (this || _global).container.find("div.chosen-drop").first();
      (this || _global).search_field = (this || _global).container.find("input").first();
      (this || _global).search_results = (this || _global).container.find("ul.chosen-results").first();
      this.search_field_scale();
      (this || _global).search_no_results = (this || _global).container.find("li.no-results").first();

      if ((this || _global).is_multiple) {
        (this || _global).search_choices = (this || _global).container.find("ul.chosen-choices").first();
        (this || _global).search_container = (this || _global).container.find("li.search-field").first();
      } else {
        (this || _global).search_container = (this || _global).container.find("div.chosen-search").first();
        (this || _global).selected_item = (this || _global).container.find(".chosen-single").first();
      }

      this.results_build();
      this.set_tab_index();
      return this.set_label_behavior();
    };

    Chosen.prototype.on_ready = function () {
      return (this || _global).form_field_jq.trigger("chosen:ready", {
        chosen: this || _global
      });
    };

    Chosen.prototype.register_observers = function () {
      (this || _global).container.on("touchstart.chosen", function (_this) {
        return function (evt) {
          _this.container_mousedown(evt);
        };
      }(this || _global));

      (this || _global).container.on("touchend.chosen", function (_this) {
        return function (evt) {
          _this.container_mouseup(evt);
        };
      }(this || _global));

      (this || _global).container.on("mousedown.chosen", function (_this) {
        return function (evt) {
          _this.container_mousedown(evt);
        };
      }(this || _global));

      (this || _global).container.on("mouseup.chosen", function (_this) {
        return function (evt) {
          _this.container_mouseup(evt);
        };
      }(this || _global));

      (this || _global).container.on("mouseenter.chosen", function (_this) {
        return function (evt) {
          _this.mouse_enter(evt);
        };
      }(this || _global));

      (this || _global).container.on("mouseleave.chosen", function (_this) {
        return function (evt) {
          _this.mouse_leave(evt);
        };
      }(this || _global));

      (this || _global).search_results.on("mouseup.chosen", function (_this) {
        return function (evt) {
          _this.search_results_mouseup(evt);
        };
      }(this || _global));

      (this || _global).search_results.on("mouseover.chosen", function (_this) {
        return function (evt) {
          _this.search_results_mouseover(evt);
        };
      }(this || _global));

      (this || _global).search_results.on("mouseout.chosen", function (_this) {
        return function (evt) {
          _this.search_results_mouseout(evt);
        };
      }(this || _global));

      (this || _global).search_results.on("mousewheel.chosen DOMMouseScroll.chosen", function (_this) {
        return function (evt) {
          _this.search_results_mousewheel(evt);
        };
      }(this || _global));

      (this || _global).search_results.on("touchstart.chosen", function (_this) {
        return function (evt) {
          _this.search_results_touchstart(evt);
        };
      }(this || _global));

      (this || _global).search_results.on("touchmove.chosen", function (_this) {
        return function (evt) {
          _this.search_results_touchmove(evt);
        };
      }(this || _global));

      (this || _global).search_results.on("touchend.chosen", function (_this) {
        return function (evt) {
          _this.search_results_touchend(evt);
        };
      }(this || _global));

      (this || _global).form_field_jq.on("chosen:updated.chosen", function (_this) {
        return function (evt) {
          _this.results_update_field(evt);
        };
      }(this || _global));

      (this || _global).form_field_jq.on("chosen:activate.chosen", function (_this) {
        return function (evt) {
          _this.activate_field(evt);
        };
      }(this || _global));

      (this || _global).form_field_jq.on("chosen:open.chosen", function (_this) {
        return function (evt) {
          _this.container_mousedown(evt);
        };
      }(this || _global));

      (this || _global).form_field_jq.on("chosen:close.chosen", function (_this) {
        return function (evt) {
          _this.close_field(evt);
        };
      }(this || _global));

      (this || _global).search_field.on("blur.chosen", function (_this) {
        return function (evt) {
          _this.input_blur(evt);
        };
      }(this || _global));

      (this || _global).search_field.on("keyup.chosen", function (_this) {
        return function (evt) {
          _this.keyup_checker(evt);
        };
      }(this || _global));

      (this || _global).search_field.on("keydown.chosen", function (_this) {
        return function (evt) {
          _this.keydown_checker(evt);
        };
      }(this || _global));

      (this || _global).search_field.on("focus.chosen", function (_this) {
        return function (evt) {
          _this.input_focus(evt);
        };
      }(this || _global));

      (this || _global).search_field.on("cut.chosen", function (_this) {
        return function (evt) {
          _this.clipboard_event_checker(evt);
        };
      }(this || _global));

      (this || _global).search_field.on("paste.chosen", function (_this) {
        return function (evt) {
          _this.clipboard_event_checker(evt);
        };
      }(this || _global));

      if ((this || _global).is_multiple) {
        return (this || _global).search_choices.on("click.chosen", function (_this) {
          return function (evt) {
            _this.choices_click(evt);
          };
        }(this || _global));
      } else {
        return (this || _global).container.on("click.chosen", function (evt) {
          evt.preventDefault();
        });
      }
    };

    Chosen.prototype.destroy = function () {
      $((this || _global).container[0].ownerDocument).off("click.chosen", (this || _global).click_test_action);

      if ((this || _global).form_field_label.length > 0) {
        (this || _global).form_field_label.off("click.chosen");
      }

      if ((this || _global).search_field[0].tabIndex) {
        (this || _global).form_field_jq[0].tabIndex = (this || _global).search_field[0].tabIndex;
      }

      (this || _global).container.remove();

      (this || _global).form_field_jq.removeData("chosen");

      return (this || _global).form_field_jq.show();
    };

    Chosen.prototype.search_field_disabled = function () {
      (this || _global).is_disabled = (this || _global).form_field.disabled || (this || _global).form_field_jq.parents("fieldset").is(":disabled");

      (this || _global).container.toggleClass("chosen-disabled", (this || _global).is_disabled);

      (this || _global).search_field[0].disabled = (this || _global).is_disabled;

      if (!(this || _global).is_multiple) {
        (this || _global).selected_item.off("focus.chosen", (this || _global).activate_field);
      }

      if ((this || _global).is_disabled) {
        return this.close_field();
      } else if (!(this || _global).is_multiple) {
        return (this || _global).selected_item.on("focus.chosen", (this || _global).activate_field);
      }
    };

    Chosen.prototype.container_mousedown = function (evt) {
      var ref;

      if ((this || _global).is_disabled) {
        return;
      }

      if (evt && ((ref = evt.type) === "mousedown" || ref === "touchstart") && !(this || _global).results_showing) {
        evt.preventDefault();
      }

      if (!(evt != null && $(evt.target).hasClass("search-choice-close"))) {
        if (!(this || _global).active_field) {
          if ((this || _global).is_multiple) {
            (this || _global).search_field.val("");
          }

          $((this || _global).container[0].ownerDocument).on("click.chosen", (this || _global).click_test_action);
          this.results_show();
        } else if (!(this || _global).is_multiple && evt && ($(evt.target)[0] === (this || _global).selected_item[0] || $(evt.target).parents("a.chosen-single").length)) {
          evt.preventDefault();
          this.results_toggle();
        }

        return this.activate_field();
      }
    };

    Chosen.prototype.container_mouseup = function (evt) {
      if (evt.target.nodeName === "ABBR" && !(this || _global).is_disabled) {
        return this.results_reset(evt);
      }
    };

    Chosen.prototype.search_results_mousewheel = function (evt) {
      var delta;

      if (evt.originalEvent) {
        delta = evt.originalEvent.deltaY || -evt.originalEvent.wheelDelta || evt.originalEvent.detail;
      }

      if (delta != null) {
        evt.preventDefault();

        if (evt.type === "DOMMouseScroll") {
          delta = delta * 40;
        }

        return (this || _global).search_results.scrollTop(delta + (this || _global).search_results.scrollTop());
      }
    };

    Chosen.prototype.blur_test = function (evt) {
      if (!(this || _global).active_field && (this || _global).container.hasClass("chosen-container-active")) {
        return this.close_field();
      }
    };

    Chosen.prototype.close_field = function () {
      $((this || _global).container[0].ownerDocument).off("click.chosen", (this || _global).click_test_action);
      (this || _global).active_field = false;
      this.results_hide();

      (this || _global).container.removeClass("chosen-container-active");

      this.clear_backstroke();
      this.show_search_field_default();
      this.search_field_scale();
      return (this || _global).search_field.blur();
    };

    Chosen.prototype.activate_field = function () {
      if ((this || _global).is_disabled) {
        return;
      }

      (this || _global).container.addClass("chosen-container-active");

      (this || _global).active_field = true;

      (this || _global).search_field.val((this || _global).search_field.val());

      return (this || _global).search_field.focus();
    };

    Chosen.prototype.test_active_click = function (evt) {
      var active_container;
      active_container = $(evt.target).closest(".chosen-container");

      if (active_container.length && (this || _global).container[0] === active_container[0]) {
        return (this || _global).active_field = true;
      } else {
        return this.close_field();
      }
    };

    Chosen.prototype.results_build = function () {
      (this || _global).parsing = true;
      (this || _global).selected_option_count = null;
      (this || _global).results_data = SelectParser.select_to_array((this || _global).form_field);

      if ((this || _global).is_multiple) {
        (this || _global).search_choices.find("li.search-choice").remove();
      } else {
        this.single_set_selected_text();

        if ((this || _global).disable_search || (this || _global).form_field.options.length <= (this || _global).disable_search_threshold) {
          (this || _global).search_field[0].readOnly = true;

          (this || _global).container.addClass("chosen-container-single-nosearch");
        } else {
          (this || _global).search_field[0].readOnly = false;

          (this || _global).container.removeClass("chosen-container-single-nosearch");
        }
      }

      this.update_results_content(this.results_option_build({
        first: true
      }));
      this.search_field_disabled();
      this.show_search_field_default();
      this.search_field_scale();
      return (this || _global).parsing = false;
    };

    Chosen.prototype.result_do_highlight = function (el) {
      var high_bottom, high_top, maxHeight, visible_bottom, visible_top;

      if (el.length) {
        this.result_clear_highlight();
        (this || _global).result_highlight = el;

        (this || _global).result_highlight.addClass("highlighted");

        maxHeight = parseInt((this || _global).search_results.css("maxHeight"), 10);
        visible_top = (this || _global).search_results.scrollTop();
        visible_bottom = maxHeight + visible_top;
        high_top = (this || _global).result_highlight.position().top + (this || _global).search_results.scrollTop();
        high_bottom = high_top + (this || _global).result_highlight.outerHeight();

        if (high_bottom >= visible_bottom) {
          return (this || _global).search_results.scrollTop(high_bottom - maxHeight > 0 ? high_bottom - maxHeight : 0);
        } else if (high_top < visible_top) {
          return (this || _global).search_results.scrollTop(high_top);
        }
      }
    };

    Chosen.prototype.result_clear_highlight = function () {
      if ((this || _global).result_highlight) {
        (this || _global).result_highlight.removeClass("highlighted");
      }

      return (this || _global).result_highlight = null;
    };

    Chosen.prototype.results_show = function () {
      if ((this || _global).is_multiple && (this || _global).max_selected_options <= this.choices_count()) {
        (this || _global).form_field_jq.trigger("chosen:maxselected", {
          chosen: this || _global
        });

        return false;
      }

      (this || _global).container.addClass("chosen-with-drop");

      (this || _global).results_showing = true;

      (this || _global).search_field.focus();

      (this || _global).search_field.val(this.get_search_field_value());

      this.winnow_results();
      return (this || _global).form_field_jq.trigger("chosen:showing_dropdown", {
        chosen: this || _global
      });
    };

    Chosen.prototype.update_results_content = function (content) {
      return (this || _global).search_results.html(content);
    };

    Chosen.prototype.results_hide = function () {
      if ((this || _global).results_showing) {
        this.result_clear_highlight();

        (this || _global).container.removeClass("chosen-with-drop");

        (this || _global).form_field_jq.trigger("chosen:hiding_dropdown", {
          chosen: this || _global
        });
      }

      return (this || _global).results_showing = false;
    };

    Chosen.prototype.set_tab_index = function (el) {
      var ti;

      if ((this || _global).form_field.tabIndex) {
        ti = (this || _global).form_field.tabIndex;
        (this || _global).form_field.tabIndex = -1;
        return (this || _global).search_field[0].tabIndex = ti;
      }
    };

    Chosen.prototype.set_label_behavior = function () {
      (this || _global).form_field_label = (this || _global).form_field_jq.parents("label");

      if (!(this || _global).form_field_label.length && (this || _global).form_field.id.length) {
        (this || _global).form_field_label = $("label[for='" + (this || _global).form_field.id + "']");
      }

      if ((this || _global).form_field_label.length > 0) {
        return (this || _global).form_field_label.on("click.chosen", (this || _global).label_click_handler);
      }
    };

    Chosen.prototype.show_search_field_default = function () {
      if ((this || _global).is_multiple && this.choices_count() < 1 && !(this || _global).active_field) {
        (this || _global).search_field.val((this || _global).default_text);

        return (this || _global).search_field.addClass("default");
      } else {
        (this || _global).search_field.val("");

        return (this || _global).search_field.removeClass("default");
      }
    };

    Chosen.prototype.search_results_mouseup = function (evt) {
      var target;
      target = $(evt.target).hasClass("active-result") ? $(evt.target) : $(evt.target).parents(".active-result").first();

      if (target.length) {
        (this || _global).result_highlight = target;
        this.result_select(evt);
        return (this || _global).search_field.focus();
      }
    };

    Chosen.prototype.search_results_mouseover = function (evt) {
      var target;
      target = $(evt.target).hasClass("active-result") ? $(evt.target) : $(evt.target).parents(".active-result").first();

      if (target) {
        return this.result_do_highlight(target);
      }
    };

    Chosen.prototype.search_results_mouseout = function (evt) {
      if ($(evt.target).hasClass("active-result") || $(evt.target).parents(".active-result").first()) {
        return this.result_clear_highlight();
      }
    };

    Chosen.prototype.choice_build = function (item) {
      var choice, close_link;
      choice = $("<li />", {
        "class": "search-choice"
      }).html("<span>" + this.choice_label(item) + "</span>");

      if (item.disabled) {
        choice.addClass("search-choice-disabled");
      } else {
        close_link = $("<a />", {
          "class": "search-choice-close",
          "data-option-array-index": item.array_index
        });
        close_link.on("click.chosen", function (_this) {
          return function (evt) {
            return _this.choice_destroy_link_click(evt);
          };
        }(this || _global));
        choice.append(close_link);
      }

      return (this || _global).search_container.before(choice);
    };

    Chosen.prototype.choice_destroy_link_click = function (evt) {
      evt.preventDefault();
      evt.stopPropagation();

      if (!(this || _global).is_disabled) {
        return this.choice_destroy($(evt.target));
      }
    };

    Chosen.prototype.choice_destroy = function (link) {
      if (this.result_deselect(link[0].getAttribute("data-option-array-index"))) {
        if ((this || _global).active_field) {
          (this || _global).search_field.focus();
        } else {
          this.show_search_field_default();
        }

        if ((this || _global).is_multiple && this.choices_count() > 0 && this.get_search_field_value().length < 1) {
          this.results_hide();
        }

        link.parents("li").first().remove();
        return this.search_field_scale();
      }
    };

    Chosen.prototype.results_reset = function () {
      this.reset_single_select_options();
      (this || _global).form_field.options[0].selected = true;
      this.single_set_selected_text();
      this.show_search_field_default();
      this.results_reset_cleanup();
      this.trigger_form_field_change();

      if ((this || _global).active_field) {
        return this.results_hide();
      }
    };

    Chosen.prototype.results_reset_cleanup = function () {
      (this || _global).current_selectedIndex = (this || _global).form_field.selectedIndex;
      return (this || _global).selected_item.find("abbr").remove();
    };

    Chosen.prototype.result_select = function (evt) {
      var high, item;

      if ((this || _global).result_highlight) {
        high = (this || _global).result_highlight;
        this.result_clear_highlight();

        if ((this || _global).is_multiple && (this || _global).max_selected_options <= this.choices_count()) {
          (this || _global).form_field_jq.trigger("chosen:maxselected", {
            chosen: this || _global
          });

          return false;
        }

        if ((this || _global).is_multiple) {
          high.removeClass("active-result");
        } else {
          this.reset_single_select_options();
        }

        high.addClass("result-selected");
        item = (this || _global).results_data[high[0].getAttribute("data-option-array-index")];
        item.selected = true;
        (this || _global).form_field.options[item.options_index].selected = true;
        (this || _global).selected_option_count = null;

        if ((this || _global).is_multiple) {
          this.choice_build(item);
        } else {
          this.single_set_selected_text(this.choice_label(item));
        }

        if ((this || _global).is_multiple && (!(this || _global).hide_results_on_select || evt.metaKey || evt.ctrlKey)) {
          if (evt.metaKey || evt.ctrlKey) {
            this.winnow_results({
              skip_highlight: true
            });
          } else {
            (this || _global).search_field.val("");

            this.winnow_results();
          }
        } else {
          this.results_hide();
          this.show_search_field_default();
        }

        if ((this || _global).is_multiple || (this || _global).form_field.selectedIndex !== (this || _global).current_selectedIndex) {
          this.trigger_form_field_change({
            selected: (this || _global).form_field.options[item.options_index].value
          });
        }

        (this || _global).current_selectedIndex = (this || _global).form_field.selectedIndex;
        evt.preventDefault();
        return this.search_field_scale();
      }
    };

    Chosen.prototype.single_set_selected_text = function (text) {
      if (text == null) {
        text = (this || _global).default_text;
      }

      if (text === (this || _global).default_text) {
        (this || _global).selected_item.addClass("chosen-default");
      } else {
        this.single_deselect_control_build();

        (this || _global).selected_item.removeClass("chosen-default");
      }

      return (this || _global).selected_item.find("span").html(text);
    };

    Chosen.prototype.result_deselect = function (pos) {
      var result_data;
      result_data = (this || _global).results_data[pos];

      if (!(this || _global).form_field.options[result_data.options_index].disabled) {
        result_data.selected = false;
        (this || _global).form_field.options[result_data.options_index].selected = false;
        (this || _global).selected_option_count = null;
        this.result_clear_highlight();

        if ((this || _global).results_showing) {
          this.winnow_results();
        }

        this.trigger_form_field_change({
          deselected: (this || _global).form_field.options[result_data.options_index].value
        });
        this.search_field_scale();
        return true;
      } else {
        return false;
      }
    };

    Chosen.prototype.single_deselect_control_build = function () {
      if (!(this || _global).allow_single_deselect) {
        return;
      }

      if (!(this || _global).selected_item.find("abbr").length) {
        (this || _global).selected_item.find("span").first().after("<abbr class=\"search-choice-close\"></abbr>");
      }

      return (this || _global).selected_item.addClass("chosen-single-with-deselect");
    };

    Chosen.prototype.get_search_field_value = function () {
      return (this || _global).search_field.val();
    };

    Chosen.prototype.get_search_text = function () {
      return $.trim(this.get_search_field_value());
    };

    Chosen.prototype.escape_html = function (text) {
      return $("<div/>").text(text).html();
    };

    Chosen.prototype.winnow_results_set_highlight = function () {
      var do_high, selected_results;
      selected_results = !(this || _global).is_multiple ? (this || _global).search_results.find(".result-selected.active-result") : [];
      do_high = selected_results.length ? selected_results.first() : (this || _global).search_results.find(".active-result").first();

      if (do_high != null) {
        return this.result_do_highlight(do_high);
      }
    };

    Chosen.prototype.no_results = function (terms) {
      var no_results_html;
      no_results_html = this.get_no_results_html(terms);

      (this || _global).search_results.append(no_results_html);

      return (this || _global).form_field_jq.trigger("chosen:no_results", {
        chosen: this || _global
      });
    };

    Chosen.prototype.no_results_clear = function () {
      return (this || _global).search_results.find(".no-results").remove();
    };

    Chosen.prototype.keydown_arrow = function () {
      var next_sib;

      if ((this || _global).results_showing && (this || _global).result_highlight) {
        next_sib = (this || _global).result_highlight.nextAll("li.active-result").first();

        if (next_sib) {
          return this.result_do_highlight(next_sib);
        }
      } else {
        return this.results_show();
      }
    };

    Chosen.prototype.keyup_arrow = function () {
      var prev_sibs;

      if (!(this || _global).results_showing && !(this || _global).is_multiple) {
        return this.results_show();
      } else if ((this || _global).result_highlight) {
        prev_sibs = (this || _global).result_highlight.prevAll("li.active-result");

        if (prev_sibs.length) {
          return this.result_do_highlight(prev_sibs.first());
        } else {
          if (this.choices_count() > 0) {
            this.results_hide();
          }

          return this.result_clear_highlight();
        }
      }
    };

    Chosen.prototype.keydown_backstroke = function () {
      var next_available_destroy;

      if ((this || _global).pending_backstroke) {
        this.choice_destroy((this || _global).pending_backstroke.find("a").first());
        return this.clear_backstroke();
      } else {
        next_available_destroy = (this || _global).search_container.siblings("li.search-choice").last();

        if (next_available_destroy.length && !next_available_destroy.hasClass("search-choice-disabled")) {
          (this || _global).pending_backstroke = next_available_destroy;

          if ((this || _global).single_backstroke_delete) {
            return this.keydown_backstroke();
          } else {
            return (this || _global).pending_backstroke.addClass("search-choice-focus");
          }
        }
      }
    };

    Chosen.prototype.clear_backstroke = function () {
      if ((this || _global).pending_backstroke) {
        (this || _global).pending_backstroke.removeClass("search-choice-focus");
      }

      return (this || _global).pending_backstroke = null;
    };

    Chosen.prototype.search_field_scale = function () {
      var div, i, len, style, style_block, styles, width;

      if (!(this || _global).is_multiple) {
        return;
      }

      style_block = {
        position: "absolute",
        left: "-1000px",
        top: "-1000px",
        display: "none",
        whiteSpace: "pre"
      };
      styles = ["fontSize", "fontStyle", "fontWeight", "fontFamily", "lineHeight", "textTransform", "letterSpacing"];

      for (i = 0, len = styles.length; i < len; i++) {
        style = styles[i];
        style_block[style] = (this || _global).search_field.css(style);
      }

      div = $("<div />").css(style_block);
      div.text(this.get_search_field_value());
      $("body").append(div);
      width = div.width() + 25;
      div.remove();

      if ((this || _global).container.is(":visible")) {
        width = Math.min((this || _global).container.outerWidth() - 10, width);
      }

      return (this || _global).search_field.width(width);
    };

    Chosen.prototype.trigger_form_field_change = function (extra) {
      (this || _global).form_field_jq.trigger("input", extra);

      return (this || _global).form_field_jq.trigger("change", extra);
    };

    return Chosen;
  }(AbstractChosen);
}).call(exports);
export default exports;